import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import axiosInstance from "./Interceptors/axiosInterceptor";



const UploadPost = ({ onSuccess }) => {
  const [file, setFile] = useState(null);
  const [caption, setCaption] = useState("");
  // const [location, setLocation] = useState({ latitude: null, longitude: null });
  // const [showMapModal, setShowMapModal] = useState(false);

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const onCaptionChange = (e) => {
    setCaption(e.target.value);
  };

  // const handleClose = () => setShowMapModal(false);
  // const handleShow = () => setShowMapModal(true);

  // useEffect(() => {
  //   let map, autocomplete;
    
  //   const initMap = () => {
  //     map = new window.google.maps.Map(document.getElementById("map"), {
  //       center: { lat: 20.5937, lng: 78.9629 }, // Default location: India
  //       zoom: 6,
  //     });

  //     // Create a search input element
  //     const input = document.createElement("input");
  //     input.setAttribute("id", "search-box");
  //     input.setAttribute("type", "text");
  //     input.setAttribute("placeholder", "Search location...");
  //     input.style.cssText = `
  //       box-sizing: border-box;
  //       border: 1px solid transparent;
  //       width: 240px;
  //       height: 40px;
  //       margin-top: 10px;
  //       margin-left: 10px;
  //       padding: 0 12px;
  //       border-radius: 3px;
  //       font-size: 16px;
  //       z-index: 5;
  //       background-color: white;
  //       box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  //     `;

  //     // Add the search input to the map controls
  //     map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

  //     // Add Autocomplete to the search box
  //     autocomplete = new window.google.maps.places.Autocomplete(input, {
  //       types: ['geocode'], // This ensures we only get address/location results
  //     });
  //     autocomplete.bindTo("bounds", map);

  //     // Debounced function to set bounds, preventing excessive calls
    
  //     // Bias the search results towards the current map bounds
    

  //     // Add a click event listener to capture the latitude and longitude
  //     map.addListener("click", (event) => {
  //       const clickedLocation = {
  //         latitude: event.latLng.lat(),
  //         longitude: event.latLng.lng(),
  //       };
  //       setLocation(clickedLocation);
  //       //console.log("Selected Location:", clickedLocation);
  //     });

  //     // Place the autocomplete results as a marker on the map
  //     autocomplete.addListener("place_changed", () => {
  //       const place = autocomplete.getPlace();
  //       if (!place.geometry) {
  //         //console.log("No geometry found for the place");
  //         return;
  //       }

  //       const selectedLocation = {
  //         latitude: place.geometry.location.lat(),
  //         longitude: place.geometry.location.lng(),
  //       };
  //       setLocation(selectedLocation);
  //       map.setCenter(place.geometry.location);
  //       map.setZoom(15);
  //     });
  //   };

  //   // Load the Google Maps script dynamically
  //   if (showMapModal) {
  //     if (!window.google || !window.google.maps) {
  //       const script = document.createElement("script");
  //       script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCbOTIfr8_dilrRncjbhbYvLKgDXBohags&libraries=places`;
  //       script.async = true;
  //       script.defer = true;
  //       script.onload = initMap;
  //       document.head.appendChild(script);
  //     } else {
  //       // If script is already loaded
  //       initMap();
  //     }
  //   }

  //   return () => {
  //     // Cleanup the map when the modal is closed
  //     if (map) {
  //       map = null;
  //     }
  //   };
  // }, [showMapModal]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("caption", caption);
    formData.append("userId", localStorage.getItem("userId"));
    // const geoLocation = {
    //   type: 'Point',
    //   coordinates: [location.longitude, location.latitude], // [longitude, latitude]
    // };
    // formData.append("location", JSON.stringify(geoLocation));

    try {
      const res = await axiosInstance.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      //console.log(res.data);
      if (onSuccess)
      {
        onSuccess();
        window.location.reload();
      }
    } catch (err) {
      console.error("Error uploading post:", err);
    }
  };

  return (
    <div>
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Choose an image:</Form.Label>
          <Form.Control type="file" onChange={onFileChange} required />
        </Form.Group>
        <Form.Group>
          <Form.Label>Caption and Add hospital location</Form.Label>
          <Form.Control
            type="text"
            value={caption}
            onChange={onCaptionChange}
            placeholder="Caption"
            required
          />
        </Form.Group>
        {/* <Button variant="primary" onClick={handleShow}>
          Select Location coordinates
        </Button>
        <div>
          {location.latitude && location.longitude ? (
            <p>
              Selected Location: {location.latitude}, {location.longitude}
            </p>
          ) : (
            <p>No location selected</p>
          )}
        </div> */}
        <Button className="mt-2" type="submit">
          Post
        </Button>
      </Form>

     
      
    </div>
  );
};

export default UploadPost;
