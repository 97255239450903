import axios from 'axios';
// Set the base URL from the environment variable or default to the production URL

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: 'https://needia.in/api/',
  headers: {
    'Content-Type': 'application/json',
  },
});
// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the token from localStorage
    const token = localStorage.getItem('token');
    //console.log('Token: in interceptor', token);
    if (token) {
      // Set the Authorization header for every request
      config.headers.Authorization = `Bearer ${token}`;
    } 
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Handle expired session
      alert('Session expired. Please log in again.');
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.clear();
      window.location.href = '/'; // Redirect to login
    } else if (error.response) {
      console.error('Response error:', error.response);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
